import React from 'react';
import { Route, Router, Redirect } from 'react-router-dom';
import { Provider } from 'mobx-react';

import store from './store'
import client from './client'

import App from './containers/App/App';
import asyncComponent from './helpers/AsyncFunc';

const stores = {
  store: store,
  client: client,
};

//client.setToken(store.token)

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
        client.loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location }
            }}
          />
        )
    }
  />
);

const PublicRoutes = ({ history, isLoggedIn }) => {
  stores.history = history
  return (
    <Provider {...stores}>
      <Router history={history}>
        <div>
          <Route
            exact
            path={'/'}
            component={asyncComponent(() => import('./containers/Page/signin'))}
          />
          <Route
            exact
            path={'/signin'}
            component={asyncComponent(() => import('./containers/Page/signin'))}
          />
          <Route
            exact
            path={'/signup'}
            component={asyncComponent(() => import('./containers/Page/signup'))}
          />
          <Route
            exact
            path={'/confirm/:token'}
            component={asyncComponent(() => import('./containers/Page/confirm'))}
          />
          <Route
            exact
            path={'/password/forgot'}
            component={asyncComponent(() => import('./containers/Page/forgotPassword'))}
          />
          <Route
            exact
            path={'/password/reset'}
            component={asyncComponent(() => import('./containers/Page/resetPassword'))}
          />
          <Route
            exact
            path={'/policies'}
            component={asyncComponent(() => import('./containers/Page/policies'))}
          />
          <RestrictedRoute
            path="/dashboard"
            component={App}
            isLoggedIn={isLoggedIn}
          />
        </div>
      </Router>
    </Provider>
  )};

  export default PublicRoutes;
