import React from 'react';
import ReactDOM from 'react-dom';
import Rollbar from 'rollbar';
import DashApp from './dashApp';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import { NODE_ENV, VERSION } from './env';

Rollbar.init({
  accessToken: 'eab78f5b3d5e4527b542aa40a4f1e421',
  verbose: true,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: NODE_ENV,
    client: {
      javascript: {
        code_version: VERSION,
      }
    }
  },
});

ReactDOM.render(<DashApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
