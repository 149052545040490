import version from './version'
const VERSION = version.version;

let NODE_ENV = "development"

let isProduction = window.location.hostname.endsWith("app.element2.engineering")
let isStaging = window.location.hostname.endsWith("staging.element2.engineering")
let isDevelopment = window.location.hostname.endsWith("localhost")

if (isProduction) {
  NODE_ENV = "production"
} else if (isStaging) {
  NODE_ENV = "staging"
}

export {
  NODE_ENV,
  isProduction,
  isStaging,
  isDevelopment,
  VERSION,
}
