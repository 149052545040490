import React, { Component } from "react";
import { observer, inject } from "mobx-react"
import { Link } from 'react-router-dom';
import { Layout, Menu } from "antd";
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";

const { Header } = Layout;
const customizedTheme = themes[themeConfig.theme];

export default inject("client")(observer(class extends Component {
  state = {
    current: window.location.pathname.split("/")[2],
  }

  handleClick = (e) => {
    this.setState({
      current: e.key,
    });
  }

  render() {
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70
    };

    const { role } = this.props.client.getProfile()
    return (
      <TopbarWrapper>
        <Header style={styling} className={"isomorphicTopbar"} >
          <div className="isoLeft">
            <Link style={{color: "inherit", textDecoration: "none"}} to={"/dashboard"}>
              <div className={"logo"} style={{margin: "0px 10px"}} >
                Element2
              </div>
            </Link>
            <Menu
              theme="light"
              mode="horizontal"
              selectedKeys={[this.state.current]}
              onClick={this.handleClick}
              style={{ lineHeight: '64px' }}
            >
              {role === "root" &&
              <Menu.Item key="companies">
                <Link to="/dashboard/companies">Companies</Link>
              </Menu.Item>
              }
              <Menu.Item key="products">
                <Link to="/dashboard/products">Products</Link>
              </Menu.Item>
              <Menu.Item key="users">
                <Link to="/dashboard/users">Users</Link>
              </Menu.Item>
            </Menu>
          </div>

          <ul className="isoRight" style={{margin: "inherit"}}>
            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarUser />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
  }))
