import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'
import { observer, inject } from "mobx-react";
import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import TopbarDropdownWrapper from './topbarDropdown.style';

export default inject("client", "history")(observer(class TopbarUser extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  }
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  logout = () => {
    this.props.client.logout(); 
    this.props.history.push('/')
  }

  render() {
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <Link to="/dashboard/settings" className="isoDropdownLink" >
          <IntlMessages id="themeSwitcher.settings" />
        </Link>
        <a className="isoDropdownLink" onClick={this.logout} href="# ">
          <IntlMessages id="topbar.logout" />
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
          <i className="ion-ios-contact" style={{fontSize: "40px"}}/>
        </div>
      </Popover>
    );
  }
}))
