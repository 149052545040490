import { observable, computed, decorate } from "mobx";

class Store {

  me = {};

  get logged_in() {
    return this.token !== null
  };

  token = localStorage.getItem('id_token') || null;

  selectForge = null

}

decorate(Store, {
  me: observable,
  token: observable,
  logged_in: computed,
  selectForge: observable,
});

// we set the store to window.store so we have access to the store in the
// console
let store = window.store = new Store()

export default store

// this is used to log the store attrs on change for debugging
//autorun(() => {
//
//console.log("Mobx SelectName: ", store.selectName)
//})

