import React, { Component } from 'react';
import { observer, inject } from "mobx-react"
import { withWindowSizeListener } from 'react-window-size-listener';
import { Row, Button, Layout, Modal } from 'antd';
import { ThemeProvider } from 'styled-components';
import Topbar from '../Topbar/Topbar';
import AppRouter from './AppRouter';
import themes from '../../settings/themes';
import { themeConfig } from '../../settings';
import AppHolder from './commonStyle';
import './global.css';
import { VERSION } from '../../env';
import Legal from '../Page/policies'

const { Content, Footer } = Layout;

export default withWindowSizeListener(inject("client")(observer(class App extends Component {

  state = {
    showModal: false,
  }

  componentWillMount() {
    this.props.client.loggedIn()
      .then((loggedIn) => {
        if (!loggedIn) {
          this.props.history.replace('/')
        }
      })
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  render() {
    const { url } = this.props.match;
    const { height } = this.props;
    const appHeight = window.innerHeight;
    const footerLinkStyle = {
      display: "inline-block",
      margin: 5,
    }
    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <AppHolder>
          <Layout style={{ height: appHeight }}>
            <Topbar url={url} />
            <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
              <Layout
                className="isoContentMainLayout"
                style={{
                  height: height
                }}
              >
                <Content
                  className="isomorphicContent"
                  style={{
                    padding: '70px 0 0',
                    flexShrink: '0',
                    background: '#f1f3f6',
                    position: 'relative'
                  }}
                >
                  <AppRouter url={url} />
                </Content>
                <Footer
                  style={{
                    background: '#ffffff',
                    textAlign: 'center',
                    borderTop: '1px solid #ededed'
                  }}
                >
                  <Row>
                    <ul style={{padding: 0}}>
                      <li style={footerLinkStyle} onClick={this.toggleModal}><Button style={{border:0, color:"#1890ff"}}>Legal</Button></li>
                    </ul>
                  </Row>
                  <Row>
                    Element2 ©{(new Date()).getFullYear()} <a target="_blank" href="https://about.me/benadamczyk" rel="oopener noreferrer">Ben Adamczyk</a> & <a target="_blank" href="https://about.me/chadbarraford" rel="noopener noreferrer">Chad Barraford</a>
                  </Row>
                  <Row>
                    Version: {VERSION.slice(0,6)}
                  </Row>
                  <Modal
                    title="Legal"
                    visible={this.state.showModal}
                    closeable={true}
                    width={"75%"}
                    footer={null}
                    onCancel={this.toggleModal}
                    onOk={this.toggleModal}
                    style={{ top: 10 }}
                  >
                    <Legal />
                  </Modal>
                </Footer>
              </Layout>
            </Layout>
          </Layout>
        </AppHolder>
      </ThemeProvider>
    );
  }
})))
