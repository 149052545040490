import React, { Component } from 'react';
import Tabs, { TabPane } from '../../components/uielements/tabs';
import BoxWrapper from '../../components/utility/box';
import { Privacy, Terms, Cookies } from "../../custom/policies";

export default class extends Component {
  render() {
    return (
      <BoxWrapper>
        <Tabs>
          <TabPane tab={<span>Privacy Policy</span>} key="privacy">
            <Privacy />
          </TabPane>
          <TabPane tab={<span>Terms of Service</span>} key="terms">
            <Terms />
          </TabPane>
          <TabPane tab={<span>Cookie Policy</span>} key="cookies">
            <Cookies />
          </TabPane>
        </Tabs>
      </BoxWrapper>
    )
  }

}
