import axios from 'axios'
import decode from 'jwt-decode'
import { isProduction, isStaging } from './env.js'

const publicEndpoints = [
  {method: "post", path: "/login"},
  {method: "get", path: "/auth/refresh_token"},
  {method: "get", path: "/ping"},
  {method: "post", path: "/password/forget"},
  {method: "post", path: "/password/reset"},
  {method: "post", path: "/users"},
]

class Client {
  constructor() {
    if (isProduction) {
      this.baseURL = "https://api.element2.engineering"
    } else if (isStaging)  {
      this.baseURL = "https://staging.api.element2.engineering"
    } else {
      this.baseURL = "http://" + window.location.hostname + ":7777"
    }
    this.client = axios.create({
      baseURL:  this.baseURL,
      contentType: "application/json",
    })

    let idToken = this.getToken()
    if (idToken !== "") {
      this.client.defaults.headers.Authorization = "Bearer " + idToken
    }

    let company = this.getMasqueradeCompany()
    if (company[0] !== "") {
      this.client.defaults.headers.CompanyId = company[0]
    }

    // auto refresh JWT token if expired (pre-request)
    this.client.interceptors.request.use( async (config) => {

      // ignore refreshing token for public endpoints
      if (publicEndpoints.some((endpoint) => {
        // checks whether an element is even
        const method = config.method
        const url = config.url.replace(config.baseURL, "")
        return endpoint.method === method && url === endpoint.path;
      })) {
        return config
      }

      // check if we need to refresh the token

      if (this.isTokenExpired(this.getToken())) {
        console.log("Token has expired, refreshing...")
        const idToken = await this.refreshToken()
        this.setToken(idToken)
        config.headers.Authorization = "Bearer " + idToken
      }
      return config
    });
  }

  login(email, password) {
    return this.client.post("/login", {
      "email": email,
      "password": password,
    }).then(res => {
      this.setToken(res.data.token) // Setting the token in localStorage
      return Promise.resolve(res);
    })
  }

  async loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken()
    if (token === "" || token === null || token === undefined ) {
      return false
    }
    if (this.isTokenExpired(token)) {
      const idToken = await this.refreshToken()
      if (idToken === "") {
        return false
      }
      this.setToken(idToken)
      return true
    } else {
      return true
    }
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
        console.log("token is expired")
        return true;
      }
      else
        return false;
    }
    catch (err) {
      return false;
    }
  }

  setToken(idToken) {
    // Saves user token to localStorage
    localStorage.setItem('id_token', idToken)
    this.client.defaults.headers.Authorization = "Bearer " + idToken
  }

  setCompany(companyId, companyName) {
    localStorage.setItem('company_name', companyName)
    localStorage.setItem('company_id', companyId)
    this.client.defaults.headers.CompanyId = companyId
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem('id_token')
  }

  getMasqueradeCompany() {
    // Retrieves the company id from localStorage
    return [localStorage.getItem('company_id'), localStorage.getItem('company_name')]
  }

  async refreshToken() {
    try {
      const response = await this.client.get("/auth/refresh_token")
      this.setToken(response.data.token) // Setting the token in localStorage
      return response.data.token
    } catch(err) {
      window.err = err
      console.error("Err:", err)
      return ""
    }
  }

  getProfile() {
    const token = this.getToken()
    if (token) {
      return decode(token) || {}
    }
    return {}
  }

  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('id_token');
  }

  ping() {
    return this.client.get("/ping")
  }

  me() {
    return this.client.get("/auth/me")
  }

  signUp(obj) {
    return this.client.post("/users", obj)
  }

  userUpdate(id, obj) {
    return this.client.put("/users/" + id, obj)
  }

  confirm(token) {
    return this.client.post("/confirm/" + token)
  }

  forgotPassword(email) {
    return this.client.post("/password/forgot", {email: email})
  }

  resetPassword(token, password) {
    return this.client.post("/password/reset", {
      token: token,
      password: password,
    })
  }
  
  listUsers() {
    return this.client.get("/users")
  }

  getUser(id) {
    return this.client.get("/users/" + id)
  }


  listCompanies() {
    return this.client.get("/companies")
  }

  myCompany() {
    return this.client.get("/auth/company")
  }

  getCompany(id) {
    return this.client.get("/companies/" + id)
  }

  createCompany(obj) {
    return this.client.post("/companies", obj)
  }

  updateCompany(id, obj) {
    return this.client.put("/companies/" + id, obj)
  }

  listProducts(parent_id=-1) {
    return this.client.get("/products", {
      params: {
        parent_id:parent_id,
      }
    })
  }

  getProduct(id) {
    return this.client.get("/products/" + id)
  }
  
  deleteProduct(id) {
    return this.client.delete("/products/" + id)
  }

  createProduct(obj) {
    return this.client.post("/products", obj)
  }

  updateProduct(id, obj) {
    return this.client.put("/products/" + id, obj)
  }

  addProductBound(id, lock) {
    return this.client.post("/products/" + id + "/lock/" + lock)
  }

  removeProductBound(id, lock) {
    return this.client.delete("/products/" + id + "/lock/" + lock)
  }

  getProductThumbnailURL(id) {
    return this.baseURL + "/products/" + id + "/thumbnail?token=" + this.getToken()
  }

  getForgeProxyURL(productId, assetId) {
    return this.baseURL + "/products/" + productId + "/assets/" + assetId + "/viewer"
  }

  async getForgeManifest(asset) {
    const baseURL = this.getForgeProxyURL(asset.product_id, asset.id)
    const urn = btoa(asset.urn)
    try {
      const response = await this.client.get(baseURL + "/modelderivative/v2/designdata/" + urn + "/manifest")
      return response.data
    } catch(err) {
      return {}
    }
  }
  
  listAssets(id, type="", limit=0, s3url=false) {
    return this.client.get("/products/" + id + "/assets", {
      params: {
        type:type,
        limit:limit,
        s3_url:s3url.toString(),
      }
    })
  }

  async getAsset(product_id, asset_id) {
    try {
      const response = await this.client.get("/products/" + product_id + "/assets/" + asset_id)
      return response.data
    } catch(err) {
      return {}
    }
  }

  getUploadURL(product_id) {
    return this.baseURL + "/products/" + product_id + "/assets"
  }

  createAsset(product_id, rootFile, tags, file, progressFunc) {
    const config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
        progressFunc(percentCompleted)
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    }

    let data = new FormData();
    data.append('file', file);
    data.append('name', file.Name);
    data.append('rootFile', rootFile);
    data.append('tags', tags);

    return this.client.put(
      "/products/" + product_id + "/assets",
      data,
      config,
    )
  }

  deleteAsset(product_id, asset_id) {
    return this.client.delete("/products/" + product_id + "/assets/" + asset_id)
  }

}

var client = window.client = new Client()
export default client
